/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "react-bootstrap";
import logo from "../../images/logowhite.png"
import Header from "../header/header";
import "./layout.scss";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>
        <Container className="p-3">{children}</Container>
      </main>
      <footer className="footer py-3 text-white text-center">
        {/* <img src={logo} className="logo"></img> */}
        <p>© Optipholio {new Date().getFullYear()}, All rights reserved</p>
     {/*   <a href="#"> Privacy policy</a>
       &nbsp;●
       <a href="#"> Cookie Policy</a>
       
       &nbsp;●
       <a href="#"> Terms and conditions</a>
       <br>
       </br> */}
{/*        by <a href="https://twitter.com/random_chili" targer="_blank">@random_chili</a> & <a href="https://twitter.com/fil_liv" targer="_blank"> @fil_liv</a>
 */}
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
