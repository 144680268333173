
import PropTypes from "prop-types";
import React from "react";
import logo from "../../images/logowhite.png"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Button, Navbar, Nav, NavItem, Container } from "react-bootstrap";
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import "./header.scss"

const Header = ({ siteTitle }) => {

  const { languages, originalPath } = useI18next();

  return (
    <Navbar className="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} href="/">
          <img src={logo} className="logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
          <Nav className="ml-auto">

            {/*     <NavItem href="#product">
            <Nav.Link className="navbar-link" as={Link} activeClassName="active" to="#product">
              Funzionalità
          </Nav.Link>
          </NavItem> */}


            {/*  <NavItem href="#pricing">
            <Nav.Link className="navbar-link" as={Link} activeClassName="active" to="#pricing">
              Pricing
          </Nav.Link>
          </NavItem>

          
          <NavItem href="https://app.splitstack.co/auth">
            <Nav.Link className="navbar-link" as={Link} activeClassName="active" >
              <a className="navbar-link" href="https://app.splitstack.co/auth">Login</a>
          </Nav.Link>
          </NavItem>

          <NavItem href="https://app.splitstack.co/auth/sign-up">
            <a className="btn-navbar ml-5 d-xs-block" href="https://app.splitstack.co/auth/sign-up">
              Sign Up
        </a>
          </NavItem> */}
          <div className="languages">
          {languages.map((lng) => (
            
              <span key={lng}>
              <Link to={originalPath} language={lng}>
                {lng}
              </Link>
              </span>
            
          ))}
        </div>
          </Nav>
        </Navbar.Collapse>

      </Container>

    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
